<template>
  <div
      style="max-width: 700px; border-radius: 15px;"
      class="blue lighten-5 mt-16 py-8 mx-auto">
    <v-row>
      <v-col sm="4">
        <v-img
            max-width="160px"
            src="@/assets/ludwig_round.png"
            contain
            rounded
            class="mt-1 mr-3"
            style="float: right"
        >
        </v-img>
      </v-col>
      <v-col class="text-left">
        <div class="pr-8">
          <p class="body-2 mb-0 font-weight-bold blue--text">{{$t('support.title')}}</p>
          <h2>{{$t('support.header')}}</h2>
          <p class="body-2">{{$t('support.text')}}</p>
          <v-row style="">
            <v-col>
              <v-btn
                  @click="toggleTelContactForm()"
                  color="grey darken-4"
                  elevation="0"
                  class="create-event-btn"
                  style="text-decoration: underline"
                  text
              >
                {{$t('support.button_tel')}}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  href="/contact"
                  color="grey darken-4"
                  elevation="0"
                  class="create-event-btn"
                  style="text-decoration: underline"
                  text
              >
                {{$t('support.button_message')}}
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div v-if="telContactForm" class="mt-8 mr-12">
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="submitForm"
          >
            <v-text-field
                v-model="form.name"
                :rules="[rules.required, rules.length30]"
                counter
                :label="$t('contact_consultant.name')"
                required
                filled
                class="mt-0"
            ></v-text-field>
            <vue-tel-input-vuetify
                :preferred-countries="['at', 'de', 'ch', 'li']"
                :valid-characters-only="true"
                @input="onInput"
                :label="$t('contact_consultant.phone')"
                :rules="[rules.required]"
                filled
            ></vue-tel-input-vuetify>
            <p v-if="!phone.valid && phone.number !== '' && phone.number !== undefined" class="error--text mt-0">{{ $t('validation.phone') }}</p>
            <p>{{this.response}}</p>

            <v-text-field
                v-model="form.email"
                :rules="[rules.required, rules.email]"
                :label="$t('contact_consultant.email')"
                type="email"
                :hint="$t('contact_consultant.email_hint')"
                required
                filled
                rounded
                style="display: none"
            ></v-text-field>
            <v-btn
                class="mr-4 mt-4 create-event-btn"
                type="submit"
                :disabled="!valid"
                color="secondary"
                elevation="0"
                rounded
                block
                large
            >
              {{ $t('support.sendButtonTel') }}
            </v-btn>
          </v-form>

          <v-snackbar
              v-model="snackbar"
              :timeout=5000
              :color=snackbarcolor
          >
            <h3 v-html="response"></h3>
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                {{ $t('support.close') }}
              </v-btn>
            </template>
          </v-snackbar>
        </div>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import * as config from '@/api/config';
import {validationRules} from '@/utils/validation_rules';
import {generateFormData} from '@/utils/helpers';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import vuetify from "../plugins/vuetify";

Vue.use(VueTelInputVuetify, {
  vuetify,
});

export default {
  name: "PersonalSupport",
  components: {

  },
  data(){
    return {
      phone: {
        number: "",
        valid: false,
        country: undefined
      },
      telContactForm: false,
      snackbar: false,
      snackbarcolor: "success",
      valid: true,
      form: {
        name: '',
        email: 'info@kumscho.com',
        message: '',
      },
      rules: validationRules,
      response: '',
    }
  },
  methods: {
    onInput(formattedNumber, { number, valid, country }) {
      this.phone.number = number.international;
      this.phone.valid = valid;
      this.phone.country = country && country.name;
    },

    toggleTelContactForm(){
      this.telContactForm = !this.telContactForm
    },

    validate () {
      return this.$refs.form.validate()
    },

    submitForm() {
      if(this.validate() && this.phone.valid)
      {
        this.form.message = this.phone.number
        const formData = generateFormData(this.form)
        this.snackbarcolor = 'success';
        axios.post(config.KUMSCHO_API_SERVER + '/mail/contact', formData).then(response => {
          this.displayResponse(response.data)
        }).catch(error => {
          let msg = error.message ? error.message : error.response.statusText
          this.displayResponse('Error: ' + msg)
        }).finally(() => {
          this.snackbar = true
        });
      }
    },
    displayResponse(res) {
      if (res == 'Ok') {
        res = this.$t('support.success_msg')
        this.$refs.form.reset()
      }
      else this.snackbarcolor = 'error';
      this.response = res;
    }
  }
}
</script>

<style scoped>

</style>