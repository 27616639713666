<template>
  <div>
    <ContactForm class="px-8"></ContactForm>

    <PersonalSupportMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></PersonalSupportMobile>
    <PersonalSupport v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'"></PersonalSupport>
    <div class="my-16 py-4"></div>

  </div>

</template>

<script>
import ContactForm from "../components/ContactForm";
import PersonalSupport from "../components/PersonalSupport";
import PersonalSupportMobile from "../components/PersonalSupportMobile";

export default {
  name: 'Contact',
  components: {
    ContactForm,
    PersonalSupport,
    PersonalSupportMobile
  },
  methods: {
    getDisplaySize(){
      return this.$vuetify.breakpoint.name;
    },
  }
}


</script>
